.mwTable-10{
  width:10%;
}

.mwTable-15{
  width:15%;
}

.mwTable{
  overflow:hidden !important;
  font-weight:bold;
  /* table-layout:fixed; */
}

.icon-pencil{
  font-size:20px !important;
  color:cyan !important;
}

.specialhover{
  color:lime ;
}

.lime{
  color:lime;
}

.cyan{
  color:cyan;
}

.red{
  color:red;
}

.orange{
  color:orange;
}

.px3-animatedbox{
  display:none;
}

.px3-animatedbox:first-child{
  display:block !important;
}


.btn{
  font-size:calc(12px + 0.09vw) !important;
  /* width:20% !important; */
}

.DBTable tr:nth-child(even) td, th, .DBTable tr:nth-child(even):hover td{
  background-color: #444780 !important;
  color:#FFF !important;
}

.DBTable tr:nth-child(odd):hover td, .DBTable tr:nth-child(odd) td{
  background-color:transparent !important;
  color: #e4e4e4!important;
}

/*fixed header change */
/* .table-responsive { 
    max-height: 500px;
    overflow: auto ;
}
.mwTable {
  text-align: left;
  position: relative;
}

.theader {
  position: sticky;
  top:0;
  background:#000;
  z-index:9999;
}

tbody{
  z-index:2;
} */
/************/

.zeroremaining{
  background: lime !important;
  color:#000 !important;
}

label{
  font-weight:bold;
}

.mwTable tr:hover td, .mwTable tr:hover td .icon-pencil, .mwTable tr:hover td .specialhover{
  background-color:lime;
  color:#000 !important;
  cursor: pointer;
}

.mwTable td, .mwTable th{
  text-align:center;
}

option{
  color:#000 !important;
  font-size:16px;
}

.icon-pencil:hover{
  cursor:pointer;
}

.itemTotal{
  font-weight:bold;
  font-size:20px;
  float:right;
}

.mw-modalItem{
  max-width:700px !important;
}

.modal-body, .modal-header, .modal-footer{
  background:#27293d;
}

.mwResult{
  color:#F00;
  text-align:center;
  padding:5px;
}

.div-pagination-2-1{
  display:none;
}

.div-pagination-2{
  float:left;
  color:#FFF;
}

.div-pagination-1{
  float:right;
  color:#FFF;
}

.table-responsive {
  /* overflow-x: hidden !important; */
  overflow: hidden !important; /*fixed header change */
}

.modal-backdrop {
  opacity: 0.5;
}

.modal.fade.in {
  opacity: 1;
}

.modal.fade.in .modal-dialog {
  transform: translate(0,0);
}

.mw-Login{
  margin:10rem auto;
}

.mw-Login .card-header, .mw-Login .card-footer{
  background-color:#000 !important;
}

.mw-profileStats{
  color:#FFF;
}

.nav p{
  color:lime !important;
}
.px3Checkbox{
  visibility:visible !important; 
  opacity: 1 !important;
  position:relative;
  bottom:2px;
}

.typeCheckbox:not(:first-child){
  margin-left:5% !important;
}

.mw-Right{
  float:right;
}

.mw-navLink{
  font-size:smaller;
  color:orange;
  cursor:pointer;
}

.mw-NavTitle{
  font-size:smaller;
}

.active-pro a{
  display:inline !important;
  font-size:10px !important;
  line-height:inherit !important;
  padding:0 !important;
  text-transform: none !important;
  color:orange !important;
}

.active-pro{
  padding:5px !important;
}
.mwLink1{
  bottom:50px !important;
}

.mwLink2{
  bottom:30px !important;
}

.mwLink3{
  bottom:10px !important;
}

.mwLink4{
  font-weight:bold;
  color:orange;
  bottom:70px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.menu div:hover{
  cursor:pointer !important;
}

.custom2{
  margin-right:60px;
}
/* 1024×768  */
/*TODO Delete all this if I choose to goo back to transparent  */
#mainNav{
  background-color: #000 !important;
  position:fixed !important;
  z-index:10 !important;
}

.content{
  margin-top:25px !important;
}
.sidebar{
  margin-top:100px !important;
}