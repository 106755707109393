.mainDiv {
  
}
/* 
.card {
  width: 300px;
  height: 120px;
  background-color: white;
  box-shadow: -1px 3px 16px -1px rgba(0,0,0,0.75);
  border-radius: 10px;
  padding: 20px;
} */

.mainDiv .animatedContainer {
  /*width: 400px;
  height: 160px;
  overflow-x: hidden;*/
  padding: 20px;
  width:100%;
  
}

.title {
  font-size: 2.5em;
  font-weight: 600;
}

.animatedContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

@keyframes animateSlideOut {
  /* 0%   {
    margin-left: 0px;
  }
  100% {
    margin-left: -325px;
  } */
  0% {
    transform: scale(0, 0);
    transform-origin: center;
  }
  25%{
    transform: scale(1, 1);
    transform-origin: center;
  }
  50%{
    transform: scale(1.5, 1.5);
    transform-origin: center;
  }
  75% {
    transform: scale(1, 1);
    transform-origin: center;
  }
  100% {
    transform: scale(0, 0);
    transform-origin: center;
  }
}

.slideOut {
  animation: animateSlideOut 8s;
  width:100%;
  text-align:center;
  font-size:25px !important;
  background:rgb(255, 123, 0);
  margin-right: -55%;
  margin-top:50px;
  color:#000 !important;
  /* margin-left: -325px; */
  line-height:50px;
}
.slideOut p{
  font-weight:bold !important;
  word-break: break-all !important;
  width:60%;
  text-align:center;
  margin:0 auto;
  color:#000 !important;
}

/* @keyframes animateFadeIn {
  0%   {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
} */

/* .fadeIn {
  animation: animateFadeIn 60s;
  opacity:0;
} */

.px3-animatedbox{
  position: absolute !important;
  width:100%;
  z-index:99;
  /* min-height:100px; */
}

.dbTable td, .dbTable th{
  font-size:2.3vw !important;
}


.dbTable .div-pagination, .dbTable .card-header{
  display:none !important;
}

.db-wrap{
  word-break: break-all !important;
  width:50%;
}