@media all {
	.page-break	{ 
		display: block !important; 
		page-break-before: none;
	}

	.print button, .print .printhide, .print .div-pagination, .print .custom-control{
		display:none !important;
	}

	.print{
		width:100% !important;
	}

}

@media print {
	@page {size: landscape !important}

	/* TODO: FIX THIS?? */
	/* .page-break	{ 
		clear:both;
		display: block !important; 
		page-break-after: always !important; 
	} */

	.printsection{
		/* page-break-after: always !important;  */
		width:100% !important;
	}

	.sidebar, #mainNav, button, .printhide,  #__react-alert__{
		display:none !important;
	}

	.row{
		width:100% !important;
	}

	.table-bordered th, .table-bordered td{
		border:1px solid #000 !important;
	}
	

	table td{
		background-color: transparent !important;
	}

	div{
		border:0 !important;
	}

	.printsection .card-header, .printsection .card-title{
		font-size:20px !important;
	}

	*:not(.card-header, .card-title){
		color: #000 !important;
		float:none !important;
		overflow: visible !important;
		font-size:14px !important;
	}

	.card-title, .card-header{
		color: #000 !important;
	}
	input, select{
		border: 0 !important;
		border-radius:0 !important;
		border-bottom:1px solid #000 !important;
		appearance: none;
		color: #000 !important;
	}

	.content{
		padding: 0 !important;
	}

	body, .wrapper {
		height: auto !important;
	}

	.printsection{
		width: 100vw; 
	 	height: 195.6mm !important;
	}

	select{
		height:38px !important;
	}
	.col-print-1 {width:8% !important;  float:left !important;}
	.col-print-2 {width:16% !important; float:left !important;}
	.col-print-3 {width:25% !important; float:left !important;}
	.col-print-4 {width:33% !important; float:left !important;}
	.col-print-5 {width:42% !important; float:left !important;}
	.col-print-6 {width:50% !important; float:left !important;}
	.col-print-7 {width:58% !important; float:left !important;}
	.col-print-8 {width:66% !important; float:left !important;}
	.col-print-9 {width:75% !important; float:left !important;}
	.col-print-10{width:83% !important; float:left !important;}
	.col-print-11{width:92% !important; float:left !important;}
	.col-print-12{width:100% !important; float:left !important;}
}

